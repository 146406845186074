var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticStyle:{"padding-right":"0"},attrs:{"cols":"2","align":"right"}},[_c('v-container',[_c('v-row',{staticClass:"pt-3",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mb-3 mx-1 primary",on:{"click":function($event){return _vm.addCompany()}}},[_vm._v("Add Company")])],1)],1),_c('v-virtual-scroll',{staticClass:"nav-scroller",attrs:{"items":_vm.sortedCompanies,"item-height":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,attrs:{"to":'/company/' + item.id}},[[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name || item.id))])],1)]],2),_c('v-divider')]}}])})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"10"}},[(_vm.currentCompany)?_c('div',[_c('v-container',{staticClass:"pl-10 my-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',[_c('h2',[_vm._v(_vm._s(_vm.currentCompany.name || "UNNAMED COMPANY"))])]),_c('v-row',[_c('b',[_vm._v(" Courses: ")]),_vm._l((_vm.currentCompanyCourseObjects),function(item,currentCompanyCourseObjectsIndex){return _c('v-chip',{key:currentCompanyCourseObjectsIndex,staticClass:"ma-1",attrs:{"color":"blue","text-color":"white"}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.editCompanyDialog = true}}},[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v("Edit ")],1)],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-btn',{staticClass:"error",attrs:{"depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCurrentCompany()}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Delete")],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-5",attrs:{"footer-props":{
            'items-per-page-options': [10, 20, 30, -1],
          },"items-per-page":30,"headers":_vm.headers,"items":_vm.allUsers,"sort-by":"name","show-select":"","item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h2',{staticClass:"pa-2"}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-container',{staticClass:"mt-15 mb-10 py-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"\n                      3"}},[_c('v-text-field',{attrs:{"label":"Name","outlined":""},model:{value:(_vm.editedUser.name),callback:function ($$v) {_vm.$set(_vm.editedUser, "name", $$v)},expression:"editedUser.name"}})],1),_c('v-form',{model:{value:(_vm.emailValid),callback:function ($$v) {_vm.emailValid=$$v},expression:"emailValid"}},[_c('v-col',{attrs:{"cols":"\n                      3"}},[_c('v-text-field',{attrs:{"outlined":"","rules":[
                          function (v) { return !v ||
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(
                              v
                            ) ||
                            'E-mail must be valid'; } ],"label":"Email"},model:{value:(_vm.editedUser.email),callback:function ($$v) {_vm.$set(_vm.editedUser, "email", $$v)},expression:"editedUser.email"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.currentCompanyCourseObjects,"item-value":function (item) { return item.id; },"item-text":function (item) { return item.title; },"chips":"","clearable":"","multiple":"","label":"Company Courses"},model:{value:(_vm.editedUser.courses),callback:function ($$v) {_vm.$set(_vm.editedUser, "courses", $$v)},expression:"editedUser.courses"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"clearable":"","outlined":"","item-text":function (item) { return item.name || item.id; },"return-object":true,"multiple":"","chips":"","items":_vm.sortedGroups,"label":"Groups"},model:{value:(_vm.editedUser.groups),callback:function ($$v) {_vm.$set(_vm.editedUser, "groups", $$v)},expression:"editedUser.groups"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.emailValid,"icon":"","outlined":"","color":"primary","dark":""},on:{"click":_vm.addEditUser}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"mx-2 mb-2",attrs:{"outlined":"","color":"primary","dark":"","icon":""},on:{"click":_vm.closeEditUser}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.courses",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"max-width-col"},_vm._l((item.courses),function(course,courseIndex){return _c('v-chip',{key:courseIndex,staticClass:"ma-1",attrs:{"color":_vm.courseChipColor,"dark":""}},[_vm._v(_vm._s(_vm.filterCurrentCompanyCourseObjects(course).title))])}),1)]}},{key:"item.groups",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"max-width-col"},_vm._l((item.groups),function(group,groupIndex){return _c('v-chip',{key:groupIndex,staticClass:"ma-1",attrs:{"color":_vm.groupChipColor,"dark":"","to":'/coaching-group/' + group.id}},[_vm._v(_vm._s(group.name || group.id))])}),1)]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}],null,false,997882173)})],1):_vm._e()])],1),(_vm.currentCompany)?_c('div',[_c('v-dialog',{model:{value:(_vm.editCompanyDialog),callback:function ($$v) {_vm.editCompanyDialog=$$v},expression:"editCompanyDialog"}},[[_c('v-card',{attrs:{"min-width":"400"}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(" Edit Company")])],1),_c('v-text-field',{staticClass:"pa-2",attrs:{"label":"Company name","outlined":""},model:{value:(_vm.currentCompany.name),callback:function ($$v) {_vm.$set(_vm.currentCompany, "name", $$v)},expression:"currentCompany.name"}}),_c('v-select',{staticClass:"pa-2",attrs:{"label":"Courses","clearable":"","multiple":"","chips":"","outlined":"","items":_vm.allCourseIds},model:{value:(_vm.currentCompanyCourseIds),callback:function ($$v) {_vm.currentCompanyCourseIds=$$v},expression:"currentCompanyCourseIds"}}),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.saveCurrentCompany()}}},[_vm._v("Save")])],1)],1)]],2),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.editUserDialog),callback:function ($$v) {_vm.editUserDialog=$$v},expression:"editUserDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-form',{model:{value:(_vm.emailValid),callback:function ($$v) {_vm.emailValid=$$v},expression:"emailValid"}},[_c('v-text-field',{attrs:{"disabled":"","rules":[
                      function (v) { return !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(
                          v
                        ) ||
                        'E-mail must be valid'; } ],"label":"Email"},model:{value:(_vm.editedUser.email),callback:function ($$v) {_vm.$set(_vm.editedUser, "email", $$v)},expression:"editedUser.email"}}),_c('v-text-field',{attrs:{"rules":[
                      function (v) { return !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(
                          v
                        ) ||
                        'E-mail must be valid'; } ],"label":"Contact Email","hint":"Empty will copy the original email"},model:{value:(_vm.editedUser.contactEmail),callback:function ($$v) {_vm.$set(_vm.editedUser, "contactEmail", $$v)},expression:"editedUser.contactEmail"}}),_c('v-text-field',{attrs:{"label":"Name","rules":[function (v) { return !!v || 'Username must not be empty'; }]},model:{value:(_vm.editedUser.name),callback:function ($$v) {_vm.$set(_vm.editedUser, "name", $$v)},expression:"editedUser.name"}})],1),_c('v-select',{attrs:{"items":_vm.currentCompanyCourseObjects,"item-value":function (item) { return item.id; },"item-text":function (item) { return item.title; },"chips":"","multiple":"","label":"Company Courses"},model:{value:(_vm.editedUser.courses),callback:function ($$v) {_vm.$set(_vm.editedUser, "courses", $$v)},expression:"editedUser.courses"}}),_c('v-select',{attrs:{"item-text":function (item) { return item.name || item.id; },"return-object":true,"multiple":"","chips":"","items":_vm.sortedGroups,"label":"Groups"},model:{value:(_vm.editedUser.groups),callback:function ($$v) {_vm.$set(_vm.editedUser, "groups", $$v)},expression:"editedUser.groups"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeEditUser}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"disabled":!_vm.emailValid,"color":"blue darken-1","text":""},on:{"click":_vm.saveEditUser}},[_vm._v(" Save ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }