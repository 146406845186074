




































































































































































































































































































































import Vue from "vue";
import {
  companyCollection,
  groupCollection,
  userInformationCollection,
} from "@/utils/db";
import { defaultCourseSelection } from "@/utils/presets";
import {
  CoachingGroup,
  Company,
  CompanyCourseAssignment,
} from "@/models/models";
import { mapGetters } from "vuex";
import { Course } from "@/models/Course";
import { getUserContactEmailByEmail, getUserNameByEmail } from "@/utils/utils";

interface userObject {
  name: string;
  email: string;
  groups: string[];
  courses: string[];
  contactEmail?: string;
}

export default Vue.extend({
  data: () => ({
    emailValid: false,
    editCompanyDialog: false,
    editUserDialog: false,
    editedIndex: -1,
    editedUser: {} as any,
    defaultUser: {
      name: "",
      email: "",
      groups: [],
      courses: defaultCourseSelection,
    },
    headers: [
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Courses", value: "courses" },
      { text: "Groups", value: "groups" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    courseChipColor: "blue",
    groupChipColor: "orange",
  }),
  computed: {
    ...mapGetters([
      "groups",
      "allCourses",
      "sortedGroups",
      "allCourses",
      "sortedCompanies",
      "companies",
      "rhoUsers",
      "profiles",
    ]),
    formTitle(): string {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    /** Return all course ids like (c1,c2) without locale postfix (_de) */
    allCourseIds(): string[] {
      let ids = new Set<string>(
        this.allCourses.map((i: { id: string }) => i.id.split("_")[0])
      );
      return [...ids];
    },
    currentCompanyCourseObjects(): { id: string; title: string }[] {
      let titles: { id: string; title: string }[] = [];
      if (this.currentCompanyCourseIds) {
        titles = this.currentCompanyCourseIds.map((courseId) => {
          let course = this.allCourses.find((c: Course) => {
            return c.id.split("_")[0] === courseId;
          });
          return { title: course?.title || courseId, id: courseId };
        });
      }
      return titles;
    },
    currentCompanyCourseIds: {
      get(): string[] {
        let ids: string[] = [];
        if (this.currentCompany) {
          ids = this.currentCompany?.courses.map((i) => i.courseId);
        }
        return ids;
      },
      set(val: string[]) {
        val.forEach((cid) => {
          let res = this.currentCompany?.courses.filter(
            (course) => course.courseId === cid
          );
          if (res && res.length === 0) {
            this.currentCompany?.courses.push({ courseId: cid, users: [] });
          }
        });
      },
    },
    currentCompany(): Company | undefined {
      return this.companies.find(
        (item: Company) => item.id == this.$route.params.companyId
      );
    },
    allUsers: {
      get(): userObject[] {
        let res: userObject[] = [];
        this.currentCompany?.allUsers.forEach((email) => {
          res.push({
            email: email,
            name: getUserNameByEmail(email) || "-",
            contactEmail: getUserContactEmailByEmail(email),
            courses: this.userCourses(email),
            groups: this.userGroups(email),
          });
        });

        return res;
      },
    },
    courses(): CompanyCourseAssignment[] {
      return this.currentCompany?.courses || [];
    },
  },
  watch: {
    "currentCompany.id": function (newId, oldId) {
      if (newId != oldId) {
        this.closeEditUser();
      }
    },
    editUserDialog: {
      handler(val) {
        val || this.closeEditUser();
      },
    },
  },
  methods: {
    filterCurrentCompanyCourseObjects(id: string) {
      return this.currentCompanyCourseObjects.find((obj) => obj.id === id);
    },
    saveCurrentCompany() {
      if (this.currentCompany) {
        this.$store
          .dispatch("updateDocument", {
            data: this.currentCompany,
            document: companyCollection.doc(this.currentCompany.id),
          })
          .then(() => (this.editCompanyDialog = false));
      }
    },
    addEditUser(): void {
      if (this.currentCompany) {
        if (this.currentCompany.allUsers.indexOf(this.editedUser.email) >= 0) {
          alert("Email already in company!");
          this.closeEditUser();
        } else {
          this.saveEditUser();
        }
      }
    },
    saveEditUser() {
      this.editedUser = { ...this.defaultUser, ...this.editedUser };
      if (this.currentCompany) {
        if (this.editedIndex !== -1) {
          // if edit take care of old mails
          let oldEmail = this.currentCompany.allUsers[this.editedIndex];
          if (oldEmail !== this.editedUser.email) {
            alert("Attempt to change email -> not implemented yet");
            // // rm email from groups
            // this.groups.forEach((group) => {
            //   let index = group.users.indexOf(oldEmail);
            //   if (index !== -1) {
            //     group.users.splice(index, 1);
            //   }
            // });

            // // rm email from courses
            // this.currentCompany.courses.forEach((c) => {
            //   let index = c.users.indexOf(oldEmail);
            //   if (index !== -1) {
            //     c.users.splice(index, 1);
            //   }
            // });
          }
        }
        //save name and contactEmail
        if (this.editedUser?.name || this.editedUser?.contactEmail) {
          userInformationCollection
            .where("email", "==", this.editedUser.email)
            .get()
            .then((snapshot) => {
              if (!snapshot.empty) {
                let userInfoId = snapshot.docs[0].id;
                if (userInfoId) {
                  this.$store.dispatch("updateDocument", {
                    document: userInformationCollection.doc(userInfoId),
                    data: {
                      name: this.editedUser.name,
                      contactEmail:
                        this.editedUser?.contactEmail || this.editedUser.email,
                    },
                  });
                }
              } else {
                const userInfo = {
                  email: this.editedUser.email,
                  name: this.editedUser.name,
                  contactEmail:
                    this.editedUser?.contactEmail || this.editedUser.email,
                };
                this.$store.dispatch("addDocument", {
                  data: userInfo,
                  collection: userInformationCollection,
                });
              }
            });
        }

        // save groups
        this.groups.forEach((group: CoachingGroup) => {
          // for each group check if it contains the user
          // if yes: add him
          // else: make sure to remove
          if (this.editedUser.groups.map((c) => c.id).includes(group.id)) {
            group.users.indexOf(this.editedUser.email) === -1
              ? group.users.push(this.editedUser.email)
              : {};
          } else {
            var index = group.users.indexOf(this.editedUser.email);
            if (index !== -1) {
              group.users.splice(index, 1);
            }
          }
          this.$store.dispatch("updateDocument", {
            document: groupCollection.doc(group.id),
            data: group,
          });
        });
        // save courses
        // create course if not existent
        this.editedUser.courses.forEach((courseId) => {
          if (
            !this.currentCompany?.courses.some((e) => e.courseId === courseId)
          ) {
            //course doesn't exist
            alert("Course " + courseId + " does not exist");
            // let c = { users: [this.editedUser.email], courseId: courseId };
            // this.currentCompany?.courses.push(c);
          }
        });

        //add/remove users from courses
        this.currentCompany.courses.forEach((course) => {
          if (this.editedUser.courses.includes(course.courseId)) {
            course.users.indexOf(this.editedUser.email) === -1
              ? course.users.push(this.editedUser.email)
              : {};
          } else {
            var index = course.users.indexOf(this.editedUser.email);
            if (index !== -1) {
              course.users.splice(index, 1);
            }
          }
        });
        if (this.editedIndex > -1) {
          // save email
          this.currentCompany.allUsers[this.editedIndex] =
            this.editedUser.email;
        } else if (
          this.currentCompany.allUsers.indexOf(this.editedUser.email) === -1
        ) {
          this.currentCompany.allUsers.push(this.editedUser.email);
        }
        this.$store
          .dispatch("updateDocument", {
            data: this.currentCompany,
            document: companyCollection.doc(this.currentCompany.id),
          })
          .then(() => this.closeEditUser());
      }
    },
    closeEditUser() {
      this.editUserDialog = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultUser);
        this.editedIndex = -1;
      });
    },
    editUser(item: {
      email: string;
      groups: string[];
      courses: string[];
      name: string;
    }) {
      if (this.allUsers) {
        this.editedIndex = this.allUsers.indexOf(item);
        this.editedUser = Object.assign({}, item);
        this.editUserDialog = true;
      }
    },
    deleteUser(item: { email: string }) {
      if (this.currentCompany) {
        if (confirm("Do you really want to delete this user?")) {
          //delete from all users
          let index = this.currentCompany?.allUsers.findIndex(
            (email) => email == item.email
          );
          if (index >= 0) this.currentCompany?.allUsers.splice(index, 1);

          // delete from each course.users
          this.currentCompany?.courses.forEach((c) => {
            let index = c.users.findIndex((email) => email == item.email);
            if (index >= 0) c.users.splice(index, 1);
          });

          this.$store.dispatch("updateDocument", {
            document: companyCollection.doc(this.currentCompany?.id),
            data: this.currentCompany,
          });

          // delete from groups
          this.groups.forEach((group: CoachingGroup) => {
            let index = group.users.findIndex((email) => email == item.email);
            if (index >= 0) {
              group.users.splice(index, 1);
              this.$store.dispatch("updateDocument", {
                document: groupCollection.doc(group.id),
                data: { users: group.users },
              });
            }
          });
        }
      }
    },
    addCompany() {
      let company = {
        allUsers: [],
        courses: [],
        name: "",
      };
      this.$store
        .dispatch("addDocument", {
          collection: companyCollection,
          data: company,
        })
        .then((res) => this.$router.push("/company/" + res.id));
    },
    deleteCurrentCompany() {
      if (this.currentCompany) {
        if (confirm("Do you really want to delete this company?")) {
          this.$store
            .dispatch("deleteDocument", {
              document: companyCollection.doc(this.currentCompany.id),
            })
            .then(() => this.$router.push("/company/"));
        }
      }
    },
    userCourses(email: string): string[] {
      let courseIds = [] as string[];

      if (this.courses) {
        let courseAssignments = this.courses.filter(
          (course: CompanyCourseAssignment) => {
            return course.users.includes(email);
          }
        );
        courseIds = courseAssignments.reduce(
          (acc: string[], course: { courseId: string }) => [
            ...acc,
            course.courseId,
          ],
          []
        );
      }
      return courseIds;
    },
    userGroups(email: string): string[] {
      let groupAssignments = [];
      if (this.groups) {
        groupAssignments = this.groups.filter((group: CoachingGroup) =>
          group.users?.includes(email)
        );
      }
      return groupAssignments;
    },
  },
});
