






















































































































































































import Vue from "vue";
import { Coach } from "@/models/models";
import { coachCollection, groupCollection } from "@/utils/db";
import { mapGetters } from "vuex";
import { functions } from "@/utils/db";
import _ from "lodash";

export default Vue.extend({
  data: () => ({
    loading: false,
    editedCoach: {} as Coach | any,
    editCoachDialog: false,
    addCoachDialog: false,
    addCoachData: {} as Coach | any,
    editFormValid: false,
    addFormValid: false,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    nameRules: [(v) => !!v || "Name is required"],
    headers: [
      { text: "Image", value: "image" },
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Groups", value: "groups" },
      { text: "Has Admin Access", value: "userId" },
      { text: "Permissions", value: "permissions" },
      { text: "Actions", value: "actions" },
    ],
  }),
  computed: {
    ...mapGetters(["coaches", "groups", "sortedGroups"]),
    computedCoaches(): any {
      let coaches = _.cloneDeep(this.coaches);
      coaches = coaches.map((coach: any) => {
        let groups = this.sortedGroups.filter((group) => {
          return group?.coaches?.includes(coach.email);
        });
        coach.groups = groups;

        return coach;
      });
      return coaches;
    },
  },
  methods: {
    async checkUid(email, item) {
      if (email) {
        let getUidByEmail = functions.httpsCallable("getUidByEmail");
        let uid = await getUidByEmail({
          email: email,
        }).then((response) => {
          return response.data?.uid;
        });
        if (uid) {
          Vue.set(item, "userId", uid);
        } else {
          alert("No User Id found for email '" + email + "'");
        }
      } else {
        alert("Please enter email");
      }
    },
    async addCoach() {
      if (
        this.addCoachData &&
        this.coaches.filter((c) => c.email === this.addCoachData?.email)
          .length < 1
      ) {
        this.loading = true;

        let data: Coach = {
          name: this.addCoachData?.name || "",
          email: this.addCoachData?.email || "",
          image: this.addCoachData?.image || "",
          permissions: this.addCoachData?.permissions || [],
        };
        let getUidByEmail = functions.httpsCallable("getUidByEmail");
        let uid = await getUidByEmail({
          email: this.addCoachData.email,
        }).then((response) => {
          return response.data?.uid;
        });
        if (uid) data.userId = uid;

        this.$store
          .dispatch("addDocumentWithCustomId", {
            collection: coachCollection,
            documentId: this.addCoachData.email,
            data: data,
          })
          .then(() => {
            this.addCoachData = {};
            this.addCoachDialog = false;
            this.loading = false;
          });
      } else {
        alert("Maybe this email already exists?");
      }
    },
    editCoach(item) {
      (this.editedCoach = item), (this.editCoachDialog = true);
    },
    saveEditedCoach() {
      if (this.editedCoach) {
        this.$store
          .dispatch("updateDocument", {
            document: coachCollection.doc(this.editedCoach.id),
            data: this.editedCoach,
          })
          .then(() => {
            this.editedCoach = {};
            this.editCoachDialog = false;
          });
      }
    },
    deleteCoach(item) {
      if (confirm("Do you really want to delete this Coach?")) {
        // Delete Coach from all groups
        let coachGroups = this.groups.filter((group) =>
          group?.coaches?.includes(item.email)
        );
        coachGroups.forEach((group) => {
          let newCoaches = group?.coaches?.filter(
            (coach) => coach !== item.email
          );
          this.$store.dispatch("updateDocument", {
            document: groupCollection.doc(group.id),
            data: { coaches: newCoaches },
          });
        });
        // Delete Coach
        this.$store.dispatch("deleteDocument", {
          document: coachCollection.doc(item.email),
        });
      }
    },
  },
});
