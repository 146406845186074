










































































































































































































































































































import Vue from "vue";
import { functions } from "@/utils/db";
import { mapGetters } from "vuex";
import { CoachingGroup, Progress, RhoUser } from "@/models/models";
import moment from "moment";
import { Chapter } from "@/models/Chapter";
import { Course } from "@/models/Course";
import { Card } from "@/models/Card";
import { Subchapter } from "@/models/Subchapter";
import CardPreviewEntry from "@/components/CardPreview/CardPreviewEntry.vue";
import { getCourseTitle, getGroupName, getUserNameByEmail } from "@/utils/utils";
import { VProgressCircular, VRow, VCol, VVirtualScroll, VListItem, VListItemAvatar, VImg, VListItemContent, VListItemTitle, VListItemSubtitle, VDivider, VContainer, VLabel, VAvatar, VIcon, VSelect, VSpacer, VSimpleTable, VCard, VCardTitle, VCardText } from "vuetify/lib";
export default Vue.extend({
  components: { CardPreviewEntry },
  data: () => ({
    groups: [],
    loading: false,
    currentCoaching: null as string | null,
  }),
  computed: {
    ...mapGetters([
      "user",
      "allCourses",
      "rhoUsers",
      "progress",
      "batches",
      "sortedGroups",
      "coaches",
      "sortedCompanies",
    ]),
    filteredRelevantCards(): any[] {
      let res = this.relevantCards.filter(this.filterRelevantCards);
      res = res.map((item) => {
        item.inputs = this.getCardInputs(item);
        item.notes = this.getCardNotes(item.id);
        return item;
      });
      return res;
    },
    currentGroup(): CoachingGroup | any {
      let res = this.groups.find(
        (item: CoachingGroup) => item.id == this.$route.params.coachingGroupId
      );
      return res;
    },
    currentGroupCourse(): Course {
      return this.allCourses.find(
        (c: Course) => c.id.split("_")[0] === this.currentGroup.course
      );
    },
    currentUsers(): any {
      let users = this.currentGroup.users;
      users = users.map((user) => {
        user.name = getUserNameByEmail(user.email);
        return user;
      });
      return users;
    },
    relevantChapters(): Chapter[] {
      let chapters: Chapter[] = [];
      if (this.currentGroup) {
        let course = this.allCourses.find(
          (c: Course) => c.id.split("_")[0] === this.currentGroup.course
        );
        let start;
        let end;
        if (this.currentCoaching) {
          let index = this.currentGroup.coachings.findIndex((c) => {
            return c.id === this.currentCoaching;
          });
          if (index > 0) {
            start = this.currentGroup.coachings[index - 1].enableUntilChapter;
          }
          end = this.currentGroup.coachings[index].enableUntilChapter;
        }

        if (course?.modules) {
          let started = false;
          course.modules.forEach((module) => {
            if (!start) {
              // started is not defined because its the first coaching
              start = module.chapters[0].id;
              started = true;
            }
            for (let chapter of module.chapters) {
              if (started) {
                chapters.push(chapter);
              } else {
                // beginn
                if (chapter.id === start) {
                  started = true;
                }
              }
              if (end) {
                if (chapter.id === end) {
                  started = false;
                  break;
                }
              }
            }
          });
        }
      }
      return chapters;
    },
    relevantCards(): any[] {
      let cards: any[] = [];
      this.relevantChapters.forEach((chapter: Chapter) => {
        chapter.subchapters.forEach((subchapter: Subchapter) => {
          subchapter.cards.forEach((card: Card) => {
            cards.push({ ...card, subchapter: subchapter, chapter: chapter });
          });
        });
      });
      return cards;
    },
    relevantCardIds(): string[] {
      let cardIds: string[] = [];
      this.relevantChapters.forEach((chapter: Chapter) => {
        chapter.subchapters.forEach((subchapter: Subchapter) => {
          subchapter.cards.forEach((card: Card) => {
            cardIds.push(card.id);
          });
        });
      });
      return cardIds;
    },
  },
  watch: {
    "currentGroup.id": function (newId, oldId) {
      if (newId != oldId) {
        this.setCurrentCoaching();
      }
    },
  },
  mounted() {
    this.getCoachGroups();
  },
  methods: {
    async getCoachGroups() {
      this.loading = true;
      let f = functions.httpsCallable("getCoachGroups");
      let result = await f();
      this.groups = result.data.groups;//.filter((group) => group.coaches.includes(this.user.email));
      this.loading = false;
    },
    getCourseTitle(courseId: string): string {
      return getCourseTitle(courseId);
    },
    filterRelevantCards(card) {
      const inputs = this.getCardInputs(card);
      const notes = this.getCardNotes(card.id);
      if (
        (inputs.length > 0 || (notes && notes.length > 0)) &&
        (["freeinput", "multiple_choice_w_answer", "chart"].includes(
          card.type
        ) ||
          card?.elements?.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCardNotes(
      cardId: string
    ): { user: { email: string; progress: any }; note: any }[] {
      //  return all user notes for one card
      let res: { user: { email: string; progress: any }; note: any }[] = [];
      this.currentUsers.forEach((user) => {
        let note = user.progress?.notes?.find((n) => n.cardId === cardId);
        if (note) {
          res.push({ user: user, note: note.note });
        }
      });
      return res;
    },
    getCardInputs(card) {
      // return all user inputs for one card
      let res: { user: { email: string; progress: any }; answer: any }[] = [];
      this.currentUsers.forEach((user) => {
        let progressCard = user.progress?.cards?.find((c) => c.id === card.id);
        if (progressCard) {
          if (progressCard?.elements == null) {
            //Card v1
            res.push({
              user: user,
              answer: progressCard?.payload?.answer || null,
            });
          } else if (progressCard?.elements?.length > 0) {
            //Card v2
            let elementResponses = progressCard.elements.map((progressElement) => {
              let answer;

              switch(progressElement?.type) {
                case "question": {
                  let answers = card.elements.find((cardElement) => cardElement.id === progressElement.id).payload.questions;
                  //Workaround if model is like v1 course
                  if(progressElement.payload.answerIndex != null){
                    answer = answers[progressElement.payload.answerIndex];
                  } else {
                    answer = progressElement.payload.answer;
                  }
                  break;
                }
                case "sum": {
                  let str = "";
                  let answers = Object.entries(progressElement.payload.answers);
                  answers.forEach(([key, value]) => str += key + ": " + value + "; ");
                  answer = str;
                  break;
                }
                case "calculation": {
                  let answers = Object.entries(JSON.parse(progressElement.payload.results));
                  let options = card.elements?.find((cardElement) => cardElement.id === progressElement.id)?.payload?.calculations;
                  let str = "";
                  if (options != null) {
                    answers.forEach(([key, value]) => {
                      if(value != 0){
                        let title = options?.find((e) => e.storeId === key).title;
                    str += title + ": " + value + "; ";
                      }
                  });
                  answer = str;
                  } else {
                    answer = progressElement?.payload?.results
                  }
                  break;
                }
                default: 
                    answer = progressElement?.payload?.answer || progressElement?.payload?.answers || progressElement?.payload;
                    break;
              }

              return { user: user, answer: answer};
            });
            res.push(...elementResponses);
          }
        }
      });
      return res;
    },
    getGroupName(group: CoachingGroup): string {
      let name = group.name || group.id || "";

      if (group.coachings.length > 0) {
        let localeDate = moment.utc(group.coachings[0].start).local();

        if (localeDate.isValid()) {
          let ds = localeDate.format("YYYY.MM.DD HH:mm:SS");
          name = ds + "  -  " + group.coachings[0].coach;
        }
      }
      return name;
    },
    getProgress(user: { email: string }) {
      let rhoUser = this.rhoUsers.find(
        (item: RhoUser) => item.email === user.email
      );

      if (rhoUser) {
        return this.progress.find(
          (item: Progress) =>
            item.userId == rhoUser?.userId &&
            item.courseId == this.currentGroup.course
        );
      } else {
        return undefined;
      }
    },
    getTotalProgress(user) {
      // % done chapters until selected coaching
      let userProgress = user.userProgressCalculation;

      if(user.progress?.sharingOptOut){
        return "sharingOptOut";
      }

      if (
        ["noRhoUser", "noUserProgress"].includes(user.userProgressCalculation)
      ) {
        return user.userProgressCalculation;
      } else if (user.userProgressCalculation) {
        if (this.currentCoaching) {
          let coaching = this.currentGroup.coachings.find(
            (c) => c.id == this.currentCoaching
          );
          if (coaching?.enableUntilChapter === "") {
            return Math.round(userProgress.courseProgress.finished * 100);
          } else {
            let chapterProgress: number[] = [];
            userProgress.courseProgress.modules.forEach((module) => {
              for (let chapter of module.chapters) {
                if (
                  this.relevantChapters.map((c) => c.id).includes(chapter.id)
                ) {
                  chapterProgress.push(Math.round(chapter.finished * 100));
                }
              }
            });
            let sum = chapterProgress.reduce((a, b) => a + b, 0);
            return String(Math.round(sum / chapterProgress.length));
          }
        } else {
          // No coaching selected -> return all progress
          return Math.round(userProgress.courseProgress.finished * 100);
        }
      } else {
        return null;
      }
    },
    getCoachImage(group: CoachingGroup): string {
      let image = "";
      if (group.coachings.length > 0) {
        image = group.coachings[0].coachImage;
      }
      return image;
    },
    setCurrentCoaching(): void {
      this.currentCoaching = null;
      let today = new Date();
      if (this.currentGroup?.coachings) {
        for (let i = 0; i < this.currentGroup.coachings.length; i++) {
          let c = this.currentGroup.coachings[i];
          if (!c.start) {
            continue;
          } else {
            let coachingDate = new Date(c.start);
            if (today < coachingDate) {
              this.currentCoaching = c.id;
              break;
            }
          }
        }
      }
    },
  },
});
